<template>
  <svg :height="height" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm11 1h4v-2h-4V7h-2v4H7v2h4v4h2v-4z"
      fill="#666"
    />
  </svg>
</template>

<script>
export default {
  name: "SmallCirclePlus",
  props: {
    height: {
      type: String,
      default: "16",
    },
  },
};
</script>
