































import { Component, Prop, Vue } from 'vue-property-decorator';
import jsonAPI from '@/api';
import TabList from './TabList.vue';

interface Reply {
  id: string;
  name: string;
  fieldGroupConfig: string;
  data: Record<string, string|number|boolean>[];
  customerName: string;
}
interface Tab {
  id: string;
  label: string;
}

interface FormRepliesResponse {
  data: Reply[]
}

@Component({
  name: 'CustomerFormReplies',
  components: {
    TabList,
  },
  computed: {
    activeReply(): Reply|null {
      if ((this as any).replies.length) {
        return (this as any).replies.filter((reply: Reply) => (reply.id === (this as any).activeTab))[0];
      }
      return null;
    },
  },
})
export default class  extends Vue {
  @Prop() customerId!: string;

  replies: Reply[] = [];
  tabs: Tab[] = [];
  activeTab = '';

  async mounted(): Promise<void> {
    const formReplies: FormRepliesResponse = await jsonAPI.get(
      `/questionnaire/replies/${this.customerId}`
    );
    this.replies = formReplies.data;
    if (this.replies.length > 0) {
      this.tabs = this.replies.map((reply) => ({
        id: reply.id,
        label: reply.name,
      }));
      this.activeTab = this.tabs[0].id;
    }

  }
  tabClicked(tab: string): void {
    this.activeTab = tab;
  }
  getFieldName(field: string): string {
    return (this as any).activeReply?.fieldGroupConfig.customFieldConfigs.filter((config: any) => config.target === field)[0].name;
  }
}
