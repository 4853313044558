









































































import { mapGetters } from "vuex";
import { Component, Prop, Vue } from "vue-property-decorator";
import jsonAPI from "@/api";
import Permission from "@/utils/permission";
import ButtonLoading from "./ButtonLoading.vue";
import { Organization } from "@/types/organization";
import { User } from "@/types/user";
import Button from "./Button.vue";
import SelectQuestionair from "./SelectQuestionair.vue";
import { Message } from "@/types/message";

interface MessagesStatusResponse {
  data: {
    hasEmail: boolean;
    hasSms: boolean;
  }
}

@Component({
  name: "NewMessageForm",
  components: { ButtonLoading, Button, SelectQuestionair },
  extends: Permission,
  computed: {
    ...mapGetters(["getOrganization", "getUser"]),
  },
  props: {
    customer: {
      type: String,
      required: true,
    },
    customerEmail: {
      type: String,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
    replyTo: {
      type: Object,
      required: false,
    },
    sendSms: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
})
export default class extends Vue {
  @Prop() customer!: string;
  @Prop() customerEmail!: string;
  @Prop() customerName!: string;
  @Prop() replyTo!: Message;

  loading = false;
  subject = "";
  message = "";
  getOrganization: Organization | undefined;
  getUser: User | undefined;
  showQuestionairs = false;
  emailConfigured = false;
  smsConfigured = false;
  sendSms = true;
  sendEmail = false;

  isSmsConfigured(): boolean {
    return this.smsConfigured;
  }
  isEmailConfigured(): boolean {
    return this.emailConfigured;
  }

  async mounted(): Promise<void> {
    const messageStatus: MessagesStatusResponse = await jsonAPI.get(
      `/messages-status`
    );
    this.emailConfigured = messageStatus.data.hasEmail;
    this.smsConfigured = messageStatus.data.hasSms;
    if (this.emailConfigured && !this.smsConfigured) {
      this.sendEmail = true;
    }
    if (this.smsConfigured && !this.emailConfigured) {
      this.sendSms = true;
    }
    if (this.replyTo) {
      this.message = `
      ______________________
      From: ${this.customerName} <${this.customerEmail}>
      ${this.replyTo.message}`;
    }
  }

  async sendMessage(): Promise<void> {
    this.loading = true;
    this.$validator.validateAll().then((result) => {
      if (result) {
        const userName = (this.getUser ?? {}).name;
        const orgId = (this.getOrganization ?? {}).id;
        const message = {
          subject: this.subject,
          message: this.message,
          type: (this.sendEmail) ? "EMAIL" : "SMS",
          direction: "OUTBOUND",
          organization_id: orgId,
          customer_id: this.customer,
          user: userName,
          reply_to: (this.replyTo) ? this.replyTo.message_id : null,
        };
        jsonAPI
          .post("messages", message)
          .then((result) => {
            window.Bus.$emit("flash-message", {
              text: "Message sent",
              type: "success",
            });
            this.loading = false;
            this.$emit("newMessage", result.data);
            this.subject = "";
            this.message = "";
            this.closeModal();
          })
          .catch((error) => {
            this.loading = false;
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$validator.errors.add({
                field: key,
                msg: error.response.data.errors[key][0],
              });
            });
            window.Bus.$emit("flash-message", {
              text: "Error happened when sending message",
              type: "error",
            });
          });
      } else {
        this.loading = false;
      }
    });
  }
  closeModal(): void {
    this.$emit("close");
  }
  async insertQuestionair(link: string): Promise<void> {
    jsonAPI
      .post("messages-form-url", {
        url: link + '/' + this.customer,
      })
      .then((result) => {
        const data: any = result.data;
        if (data && data.url) {
          this.message += data.url;
        }
      })
      .catch(() => {
        this.loading = false;
        window.Bus.$emit("flash-message", {
          text: "Error happened when sending message",
          type: "error",
        });
      });

  }
  public toggleSMS(): void {
    this.sendSms = true;
    this.sendEmail = false;
  }
  public toggleEmail(): void {
    this.sendEmail = true;
    this.sendSms = false;
  }
  public refresh(display: string): void {
    if (this.replyTo) {
      this.message = `
      ______________________
      From: ${this.customerName} <${this.customerEmail}>
      ${this.replyTo.message}`;
      this.subject = this.replyTo.subject;
    }
    if (display === "EMAIL") {
      this.toggleEmail();
    } else {
      this.toggleSMS();
    }
  }
}
