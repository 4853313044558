<template>
  <div class="modal-body add-tag">
    <form @submit.prevent="addNewTag">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input
              v-model="name"
              type="text"
              class="form-control"
              placeholder="Add a tag"
              required
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <compact-picker :value="color" @input="updateValue" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <ButtonLoading
              :loading="loading"
              :id="'add-tag'"
              :name="'add-tag'"
              :class="'btn-primary float-right'"
              :title="'Add tag'"
              :disabled="newTagLoading"
              @click="addTag"
            />
          </div>
        </div>
      </div>
    </form>

    <br />
    <div class="row">
      <div class="col-md-12">
        <p>Active Tags</p>
        <div class="tags">
          <span v-for="ctag in tagmodel.tags" :key="ctag.id">
            <Tag
              :key="ctag"
              :slug="ctag"
              :url="url"
              :tagmodel="tagmodel"
              @removetag="updateTags"
            >
              {{ ctag }}
            </Tag>
          </span>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <p>Suggested Tags</p>
        <div class="tags add-tags">
          <span v-for="tag in tags" :key="tag.id" @click="addTag(tag)">
            <Tag :key="tag.id" :slug="tag.slug">
              {{ tag.name }}
            </Tag>
          </span>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { Compact } from "vue-color";
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Tag from "@/components/UI/Tag.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import { postToApi } from "@/helpers/apiConnection";

export default {
  name: "AddTag",
  mixins: [clickaway],
  components: {
    "compact-picker": Compact,
    ButtonLoading,
    Tag,
  },
  props: {
    tagmodel: {
      type: Object,
      default: () => {
        return {
          tags: [],
        };
      },
    },
    url: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      name: null,
      color: "#000000",
      newTagLoading: false,
      tags: [],
      step_status: {
        key: "create_tag",
        status: true,
      },
    };
  },
  computed: {
    ...mapGetters(["getTags"]),
  },
  async mounted() {
    await this.$store.dispatch("GET_TAGS");
    this.tags = this.getTags;
    this.getUnsedTags();
  },
  methods: {
    getUnsedTags() {
      this.tags = this.getTags.filter(
        (i) => !this.tagmodel.tags.includes(i.slug)
      );
    },
    addTag(tag) {
      const exists = this.checkTag(tag.slug);
      if (exists) {
        this.newTagLoading = false;
        window.Bus.$emit("flash-message", {
          text: `Tag slug "${tag.slug}" already exists`,
          type: "error",
        });
        return;
      }

      const new_tag = tag.slug;
      const tagmodel = Object.assign({}, this.tagmodel);
      tagmodel.tags.push(new_tag);
      let data = {};
      Object.keys(tagmodel).forEach((prop) => {
        if (tagmodel[prop]) {
          data[prop] = tagmodel[prop];
        }
      });

      jsonAPI
        .put("/" + this.url + "/" + tagmodel.id, data)
        .then(() => {
          window.Bus.$emit("flash-message", {
            text: `${this.url} updated`,
            type: "success",
          });

          this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Update error",
            type: "error",
          });
        });
      this.tags = this.tags.filter((i) => i !== tag.slug);
    },
    updateValue(value) {
      this.color = value.hex;
    },
    async addNewTag() {
      this.newTagLoading = true;
      const name_slug = this.name
        .split(" ")
        .join("_")
        .toLowerCase();
      const exists = this.checkTag(name_slug);
      if (exists) {
        this.newTagLoading = false;
        window.Bus.$emit("flash-message", {
          text: `Tag slug "${name_slug}" already exists`,
          type: "error",
        });
        return;
      }
      const res = await postToApi("/tag", {
        name: this.name,
        slug: name_slug,
        color: this.color,
      }).catch(() => {
        this.newTagLoading = false;
      });
      this.newTagLoading = false;
      if (res) {
        window.Bus.$emit("flash-message", {
          text: `Tag "${this.name}" added`,
          type: "success",
        });
        const new_tag = {
          name: this.name,
          slug: name_slug,
        };

        await this.$store.dispatch("UPDATE_TAGS", res);
        this.addTag(new_tag);
      }
    },
    checkTag(slug) {
      var exists = this.tagmodel.tags.some((t_slug) => {
        return t_slug === slug;
      });
      return exists;
    },
    updateTags(tagmodel) {
      this.tags = this.getTags.filter((i) => !tagmodel.tags.includes(i.slug));
      const newtagmodel = Object.assign({}, this.tagmodel);
      newtagmodel.tags = tagmodel.tags;
      this.$emit("updateTagModelObject", newtagmodel);
    },
  },
};
</script>

<style scoped>
.form-group input {
  margin-bottom: 40px;
}
.add-tags .badge:hover {
  cursor: pointer;
}
.tags span {
  display: inline-block;
  margin-bottom: 5px;
}
</style>
