import { updateApi } from './apiConnection';
import { ConfigurationData } from '../types/configurationData';

export const updateApiConfigurationData = async function (viewId: string, view: ConfigurationData): Promise<ConfigurationData|null> {
  const result = await updateApi(
    `/configurationData/${viewId}`,
    JSON.parse(JSON.stringify(view)),
    "View updated"
  ).catch(() => {
    window.Bus.$emit("flash-message", {
      text: "Error saving new order of the fields",
      type: "error",
    });
    return null;
  });
  return result as ConfigurationData;
}