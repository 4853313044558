import { render, staticRenderFns } from "./CustomerFormReplies.vue?vue&type=template&id=0a26d6a6&scoped=true&"
import script from "./CustomerFormReplies.vue?vue&type=script&lang=ts&"
export * from "./CustomerFormReplies.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a26d6a6",
  null
  
)

export default component.exports