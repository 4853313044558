<template>
  <div class="products-log col-12 mt-3">
    <data-table :headers="headers()" :data="products" />
  </div>
</template>

<script>
// Componentns
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "ProductsLog",
  components: {
    DataTable,
  },
  props: {
    products: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    headers() {
      return [
        { title: "Sku", key: "sku" },
        { title: "Name", key: "name" },
        { title: "Quantity", key: "qty_ordered" },
        { title: "Price", key: "price" },
        { title: "Total", key: "total" },
      ];
    },
  },
};
</script>
