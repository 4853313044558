





















import { Component, Vue } from "vue-property-decorator";
import { loadFromApi } from "@/helpers/apiConnection";

@Component({
  name: "SelectQuestionair",
})
export default class SelectQuestionair extends Vue {
  public questionairs: any[] = [];
  public selectedQuestionair = "";
  mounted(): void {
    loadFromApi("questionnaire/config").then((response: any) => {
      this.questionairs = response.data;
    });
  }
  selectQuestionair(): void {
    if (this.selectedQuestionair) {
      const url = `https://customer.automation.app/form/${this.selectedQuestionair}`;
      this.$emit("insert", url);
    }
  }
}
