










































import jsonAPI from "@/api";
import { Component, Prop, Vue } from "vue-property-decorator";
import Sms from "../Svg/Sms.vue";
import Email from "../Svg/Email.vue";

enum DIRECTION {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}
interface Message {
  id: number;
  type: string;
  message: string;
  direction: DIRECTION.INBOUND | DIRECTION.OUTBOUND;
  created_at: string;
  user: string;
}
interface MessagesResponse {
  data: {
    data: Message[];
  };
}

@Component({
  components: {
    Sms,
    Email,
  },
})
export default class extends Vue {
  @Prop() customer!: string;
  @Prop() customerName!: string;

  showSms = true;
  showEmail = true;

  name(message: Message): string {
    return message.user ?? this.customerName;
  }
  toggleSMS(): void {
    this.showSms = !this.showSms;
    this.refresh();
  }
  toggleEmail(): void {
    this.showEmail = !this.showEmail;
    this.refresh();
  }
  // loading: boolean = false;
  messages: Message[] = [];
  async mounted(): Promise<void> {
    const messages: MessagesResponse = await jsonAPI.get(
      `/messages?customer=${this.customer}&page=1&per_page=10&sort=created_at&dir=desc`
    );
    this.messages = messages.data.data;
  }
  classes(message: Message) {
    return {
      "inbound mr-9 bg-info text-light":
        message.direction === DIRECTION.INBOUND,
      "outbound ml-9 bg-light": message.direction === DIRECTION.OUTBOUND,
    };
  }
  isInbound(message: Message): boolean {
    return message.direction === DIRECTION.INBOUND;
  }
  async refresh(): Promise<void> {
    const messages: MessagesResponse = await jsonAPI.get(
      `/messages?customer=${this.customer}&page=1&per_page=10&sort=created_at&dir=desc&show_sms=${this.showSms}&show_email=${this.showEmail}`
    );
    this.messages = messages.data.data;
  }
  reply(message: Message): void {
    this.$emit("reply", message);
  }
}
