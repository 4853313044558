<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">Send message</h3>
      <div slot="content">
        <new-message-form
          @close="isModalVisible = false"
          @newMessage="newMessage"
          :customer="customer.id"
          :customerEmail="customer.email"
          :customerName="customerName"
          :replyTo="replyToMessage"
          ref="newMessageFormRef"
        />
      </div>
    </prompt>
    <div class="row fadeInUp" role="main">
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-12">
            <h5>Contact</h5>
            <div class="card border-0 shadow-sm mb-6">
              <div class="card-body pb-4">
                <div
                  class="
                    mb-4
                    pb-3
                    d-flex
                    align-items-center
                    justify-content-between
                    border-bottom
                  "
                >
                  <Avatar
                    class="mr-5"
                    :avatar-image="customer.avatar_url"
                    :first-name="customer.first_name"
                    :last-name="customer.last_name"
                    :customer-type="customer.type"
                  />
                  <div>
                    <h2 class="mb-1 d-flex justify-content-between">
                      <strong
                        >{{ customer.first_name }}
                        {{ customer.last_name }}</strong
                      >
                    </h2>
                    <p class="mb-1 d-flex justify-content-between">
                      <span>Contact Id:</span>
                      <span><i>#{{ customer.customer_id }}</i></span>
                    </p>
                    <p class="mb-1 d-flex justify-content-between">
                      <span>Customer Type:</span>
                      <span><i class="status py-1 px-3 ml-3" :class="currentStatus.key">{{ currentStatus.label }}</i></span>
                    </p>
                  </div>
                  <div class="ml-auto">
                    <p class="mb-1 d-flex justify-content-between">
                      <small>Created:</small>
                      <small>
                        {{ customer.created_at | formatLocaleDateString }}
                      </small>
                    </p>
                    <p class="mb-1 d-flex justify-content-between">
                      <small class="pr-3">Updated:</small>
                      <small>
                        {{ customer.updated_at | formatLocaleDateString }}
                      </small>
                    </p>
                    <p
                      v-if="customer.first_order"
                      class="mb-1 d-flex justify-content-between"
                    >
                      <small class="pr-3"> First order </small>
                      <small>
                        {{ customer.first_order | formatLocaleDateString }}
                      </small>
                    </p>
                    <p
                      v-if="customer.latest_order"
                      class="mb-1 d-flex justify-content-between"
                    >
                      <small class="pr-3"> Latest order </small>
                      <small>
                        {{ customer.latest_order | formatLocaleDateString }}
                      </small>
                    </p>
                  </div>
                </div>

                <div class="d-flex text-center">
                  <p class="mb-1 flex-fill border-right">
                    <small><strong>CLV</strong> <TooltipVue :title="'Customer lifetime value'"/></small>
                    <small>
                      <money-format
                        :value="customer.total_order_value / 100"
                        :locale="'en'"
                        :currency-code="customer.currency"
                      />
                    </small>
                  </p>
                  <p class="mb-1 flex-fill border-right">
                    <small><strong>AOV</strong> <TooltipVue :title="'Average order value'"/></small>
                    <small>
                      <money-format
                        :value="customer.average_order_value / 100"
                        :locale="'en'"
                        :currency-code="customer.currency"
                      />
                    </small>
                  </p>
                  <p class="mb-1 flex-fill border-right">
                    <small><strong>Discount</strong></small>
                    <small>
                      <money-format
                        :value="customer.discount_value / 100"
                        :locale="'en'"
                        :currency-code="customer.currency"
                      />
                    </small>
                  </p>
                  <p class="mb-1 flex-fill">
                    <small><strong>No. orders</strong></small>
                    <small class="d-block">
                      {{ customer.order_count }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="d-flex flex-wrap card-body py-2 pb-2 border-top">
                <Tag v-for="tag in customer.tags" :key="tag" :slug="tag" />
                <button
                  class="
                    badge badge-pill
                    d-inline
                    border
                    rounded-pill
                    m-1
                    px-2
                    py-1
                    btn btn-primary btn-sm
                  "
                  @click="openAddTag"
                >
                  <small-circle-plus :height="'12'" />
                  Add or Delete tags
                </button>
                <prompt
                  :active="isAddTagVisible"
                  @close="isAddTagVisible = false"
                >
                  <h3 slot="header">Add Tags</h3>
                  <div slot="content">
                    <add-tag
                      v-if="customer.id"
                      :tagmodel="customer"
                      :url="'customer'"
                      @updateTagModelObject="updateObject"
                    />
                  </div>
                </prompt>
              </div>
              <div class="card-body border-top">
                <TabList :tabs="tabs" @tabClicked="tabClick" />
                <div class="media" v-if="activeTab === 'details'">
                  <div class="media-body">
                    <div class="col-md-12 d-flex px-0">
                      <div
                        class="form-group col-md-6 pl-0 pr-2"
                        :class="{ 'is-invalid': errors.has('first_name') }"
                      >
                        <label
                          for="first_name"
                          class="control-label text-capitalize"
                          >First name</label
                        >
                        <input
                          id="first_name"
                          v-model="customer.first_name"
                          v-validate="{ required: false }"
                          type="text"
                          name="first_name"
                          class="form-control"
                        />
                        <span
                          v-show="errors.has('first_name')"
                          class="invalid-feedback"
                          >{{ errors.first("first_name") }}</span
                        >
                      </div>
                      <div
                        class="form-group col-md-6 pr-0 pl-2"
                        :class="{ 'is-invalid': errors.has('last_name') }"
                      >
                        <label
                          for="last_name"
                          class="control-label text-capitalize"
                          >Last name</label
                        >
                        <input
                          id="last_name"
                          v-model="customer.last_name"
                          v-validate="{ required: false }"
                          type="text"
                          name="last_name"
                          class="form-control"
                        />
                        <span
                          v-show="errors.has('last_name')"
                          class="invalid-feedback"
                          >{{ errors.first("last_name") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-md-12 d-flex px-0">
                      <div
                        class="form-group required col-md-6 pl-0 pr-2"
                        :class="{ 'is-invalid': errors.has('email') }"
                      >
                        <label for="email" class="control-label text-capitalize"
                          >Email</label
                        >
                        <input
                          id="email"
                          v-model="customer.email"
                          v-validate="{ required: true }"
                          type="text"
                          name="email"
                          class="form-control"
                        />
                        <span
                          v-show="errors.has('email')"
                          class="invalid-feedback"
                          >{{ errors.first("email") }}</span
                        >
                      </div>
                      <div
                        class="form-group col-md-6 pr-0 pl-2"
                        :class="{ 'is-invalid': errors.has('phone_number') }"
                      >
                        <label
                          for="telephone"
                          class="control-label text-capitalize"
                          >Phone Number</label
                        >
                        <TelephoneInput
                          @valueChange="setTelephoneValue"
                          @countryChange="setCountryValue"
                          :telephone="customerTelephone"
                          id="telephone"
                          name="telephone"
                        />
                        <span
                          v-show="errors.has('telephone')"
                          class="invalid-feedback"
                          >{{ errors.first("telephone") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-md-12 d-flex px-0">
                      <div
                        class="form-group col-md-6 pl-0 pr-2"
                        :class="{ 'is-invalid': errors.has('country') }"
                      >
                        <label
                          for="country"
                          class="control-label text-capitalize"
                        >
                          Country
                        </label>
                        <multiselect
                          id="country"
                          v-model="currentCountry"
                          v-validate="{ required: true }"
                          name="country"
                          :options="countries"
                          label="name"
                          track-by="code"
                          :show-labels="false"
                          @select="changeCountry"
                        />
                        <span
                          v-show="errors.has('country')"
                          class="invalid-feedback"
                          >{{ errors.first("country") }}</span
                        >
                      </div>
                      <div class="form-group col-md-6 pr-0 pl-2">
                        <label for="status" class="control-label text-capitalize">Customer Type</label>
                        <multiselect
                          id="status"
                          v-model="currentStatus"
                          name="status"
                          :options="statusList"
                          track-by="key"
                          label="label"
                          :allow-empty="false"
                          :show-labels="false"
                          @select="changeStatus"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 d-flex px-0">
                      <div class="form-group col-md-6 pl-0 pr-2">
                        <div class="rounded p-3 border">
                          <editable-address
                            title="Shipping address"
                            simple
                            :address="customer.shipping_address"
                            :default-country="getDefaultCountry"
                          />
                          <span
                            v-show="errors.has('shipping_address')"
                            class="invalid-feedback"
                            >{{ errors.first("shipping_address") }}</span
                          >
                        </div>
                      </div>
                      <div class="form-group col-md-6 pr-0 pl-2">
                        <div class="rounded p-3 border">
                          <editable-address
                            title="Billing address"
                            simple
                            :address="customer.billing_address"
                            :default-country="getDefaultCountry"
                          />
                          <span
                            v-show="errors.has('billing_address')"
                            class="invalid-feedback"
                            >{{ errors.first("billing_address") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 d-flex px-0">
                      <CustomerFormReplies
                        class="form-group col-md-12 pr-0 pl-2"
                        v-if="customer.id"
                        :customerId="customer.id" />
                    </div>
                    <ButtonLoading
                      :class="'btn-secondary float-right'"
                      :loading="loading"
                      :title="'Update'"
                      v-on:click.native="submitForm"
                    />
                  </div>
                </div>

                <div class="media" v-if="activeTab === 'orders'">
                  <div class="media-body">
                    <div class="col-md-12 d-flex px-0">
                      <Orders-log :orders="orders.data" />
                    </div>
                  </div>
                </div>
                <div class="media" v-if="activeTab === 'products'">
                  <div class="media-body">
                    <div class="col-md-12 d-flex px-0">
                      <Products-log :products="products" />
                    </div>
                  </div>
                </div>
                <div class="media" v-if="activeTab === 'automations'">
                  <div class="media-body">
                    <div class="col-md-12 d-flex px-0">
                      <Automations-log :automations="customer.logs" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteButton
          :uri="`customer/${customer.id}`"
          :name="`customer ${customer.id}`"
          @deleted="customerDeleted"
        />
      </div>
      <div class="col-md-5">
        <div class="col-md-12 mb-5">
          <h5>Messages</h5>

          <div class="card">
            <div class="card-header">
              <ButtonLoading
                class="btn-secondary float-right"
                :title="'Send message'"
                @click.native="isModalVisible = true"
              />
            </div>
            <div class="card-body pt-0">
              <CustomerMessages
                v-if="customer.id"
                :customer="customer.id"
                :customerName="`${customer.first_name} ${customer.last_name}`"
                :ref="'customerMessages'"
                @reply="reply"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
// Modules
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import MoneyFormat from "vue-money-format";
// Components
import AddTag from "@/components/Form/AddTag.vue";
import AutomationsLog from "@/components/UI/Logs/AutomationsLog.vue";
import Avatar from "@/components/Svg/Avatar.vue";
import Default from "@/layouts/default.vue";
import EditableAddress from "@/components/UI/EditableAddress.vue";
import jsonAPI from "@/api";
import OrdersLog from "@/components/UI/Logs/OrdersLog.vue";
import ProductsLog from "@/components/UI/Logs/ProductsLog.vue";
import Prompt from "@/components/UI/Prompt.vue";
import SmallCirclePlus from "@/components/Svg/SmallCirclePlus.vue";
import Tag from "@/components/UI/Tag.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import utilities from "@/helpers/utilities";
import CustomerMessages from "@/components/UI/CustomerMessages.vue";
import CustomerFormReplies from "@/components/UI/CustomerFormReplies.vue";
import NewMessageForm from "@/components/Form/NewMessageForm.vue";
import countries from "@/helpers/countries";
import TooltipVue from "@/components/UI/Tooltip.vue";
import customerStatus from "@/helpers/customerStatus";
import TabList from "@/components/UI/TabList.vue";
import TelephoneInput from "@/components/Form/TelephoneInput.vue";
import Permission from "@/utils/permission";
import DeleteButton from "@/components/Form/DeleteButton.vue";

export default {
  name: "Customer",
  extends: Permission,
  components: {
    Default,
    Multiselect,
    MoneyFormat,
    EditableAddress,
    Avatar,
    Tag,
    AutomationsLog,
    OrdersLog,
    ProductsLog,
    SmallCirclePlus,
    Prompt,
    AddTag,
    ButtonLoading,
    CustomerMessages,
    NewMessageForm,
    TooltipVue,
    CustomerFormReplies,
    TabList,
    TelephoneInput,
    DeleteButton,
},
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  async beforeRouteEnter(to, from, next) {
    let products = [];
    const resCustomer = await jsonAPI.get("customer/" + to.params.id);
    const resOrder = await jsonAPI.get("customer/orders/" + to.params.id);
    resOrder.data.data.forEach((item) => {
      products = products.concat(item.order_items);
    });
    next((vm) => {
      vm.customer = resCustomer.data;
      vm.orders = resOrder.data;
      vm.products = products;
      if(vm.customer.type) {
        vm.currentStatus = vm.statusList.find((item) => {
          return item.key == vm.customer.type;
        });
      }
      vm.currentCountry = vm.countries.find((item) => {
        return item.code == vm.customer.country;
      });

      if (!vm.customer.shipping_address) {
        vm.customer.shipping_address = {};
      }

      if (!vm.customer.billing_address) {
        vm.customer.billing_address = {};
      }
    });
  },
  data() {
    return {
      layout: "Default",
      loading: false,
      isModalVisible: false,
      customer: {
        id: null,
      },
      orders: [],
      products: [],
      isAddTagVisible: false,
      otherorganizationtags: [],
      activeTab: "details",
      countries: countries,
      statusList: customerStatus,
      currentStatus: {
        label: '',
        key: '',
      },
      currentCountry: {
        name: '',
        code: '',
      },
      tabs: [
        {
          label: "Customer details",
          id: "details",
        },
        {
          label: "Orders",
          id: "orders",
        },
        {
          label: "Products",
          id: "products",
        },
        {
          label: "Automation log",
          id: "automations",
        },
      ],
      replyToMessage: null,
    };
  },
  computed: {
    ...mapGetters(["getOrganization"]),
    getDefaultCountry() {
      return (
        this.getOrganization.country_obj ?? {
          name: "",
          code: "",
        }
      );
    },
    customerTelephone() {
      return this.customer.telephone
        ? this.customer.telephone
        : this.customer.phone;
    },
    customerName() {
      const firstName = this.customer.first_name ?? '';
      const lastName = this.customer.last_name ?? '';
      return `${firstName} ${lastName}`;
    },
  },
  methods: {
    submitForm: function () {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          const data = utilities.cleanData(this.customer);
          const organization_country = this.getOrganization.country;
          // check the address_line if has content not to save country without address
          if (
            data.shipping_address.address_line_1 !== "" &&
            data.shipping_address.country === null
          ) {
            data.shipping_address.country_code = organization_country;
          }

          if (
            data.billing_address.address_line_1 !== "" &&
            data.billing_address.country === null
          ) {
            data.billing_address.country_code = organization_country;
          }
          if (typeof data.country === "object") {
            data.country = data.country.code;
          }
          if (typeof data.type === "object") {
            data.type = data.type.key;
          }

          jsonAPI
            .put("/customer/" + this.customer.id, data)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "Customer saved",
                type: "success",
              });
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              window.Bus.$emit("flash-message", {
                text: "Update error",
                type: "error",
              });
            });
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    openAddTag() {
      this.isAddTagVisible = true;
    },
    updateObject(newobject) {
      this.customer = newobject;
    },
    newMessage() {
      this.$refs.customerMessages.refresh();
    },
    changeStatus(event) {
      this.customer.type = event.key;
    },
    changeCountry(event) {
      this.customer.country = event.code;
    },
    setTelephoneValue(val) {
      console.log(val);
      this.customer.telephone = val.replace('+', '');
    },
    setCountryValue(val) {
      console.log("Val: " + val);
      //this.customer.country = val;
    },
    tabClick(tab) {
      console.log(tab);
      this.activeTab = tab;
    },
    customerDeleted() {
      this.$router.push({ name: "contact.index" });
    },
    reply(message) {
      this.replyToMessage = message;
      this.$nextTick(() => {
        this.$refs.newMessageFormRef.refresh(message.type);
        this.isModalVisible = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.nav-item a {
  color: #2b354f;
}
.active:before {
  content: "";
  display: block;
  position: absolute;
  transition: left 1.3s ease;
  border: 6px solid transparent;
  border-top-color: #0391d9;
  width: 0;
  height: 0;
  bottom: -12px;
  left: 50%;
  margin-left: -3px;
}
.active.nav-item a {
  color: #0391d9;
}
.nav-item:hover {
  cursor: pointer;
}
.status {
  border-radius: 0.5rem;
  font-weight: 800;
  &.customer {
    background-color: #2db6fc;
    color: #fff;
  }
  &.prospect {
    background-color: rgba(137, 243, 129, 0.5);
    color: #333;
  }
}
</style>
