<template>
    <component :is="layout">
        <div class="d-flex">
            <prompt
                :active="createCustomer"
                :title="'Create a contact'"
                @close="createCustomer = false"
            >
                <div slot="content">
                    <CreateCustomer
                        @close="createCustomerSuccess"
                    />
                </div>
            </prompt>
            <prompt
                :active="sendMessage"
                :title="'Send Message'"
                @close="sendMessage = false"
            >
                <div slot="content">
                    <MessageCustomer
                        :conditionData="conditionData"
                        :customer_status="customer_status.key"
                        @close="sendMessage = false"
                    />
                </div>
            </prompt> 
            <OverviewSidebar
                class="position-fixed p-4 justify-content-between fadeInUp"
                overview="customer"
                search-placeholder="Search for customer"
                @filter="filterCustomers"
                @filterTags="filterTags"
            >
                <FieldList
                    v-if="isEdit"
                    :fieldType="'customer'"
                    :selected-fields="selected"
                    @add="addField"
                    @deselect="removeField"
                />
            </OverviewSidebar>
            <div class="p-5 flex-fill fadeInUp has-sidebar" role="main">
                <ConditionBuilder
                    v-if="reRenderConditionBuilder"
                    @apply-filters="setConditionData"
                    :conditionData="conditionData"
                    :model="'customer'"
                />
                <ApiLoader :is-loading="loading">
                    <div class="card border-0 shadow-sm mb-4 w-100">
                        <div class="card-header">
                        <h5>
                            {{ view.data.name }}
                            <multiselect
                            v-if="!isEdit" 
                            id="status"
                            v-model="customer_status"
                            name="status"
                            :options="statusList"
                            track-by="key"
                            label="label"
                            :allow-empty="false"
                            :show-labels="false"
                            @select="changeStatus"
                            />

                            <button
                                class="btn btn-primary page-intro"
                                @click="isEdit = !isEdit"
                            >
                                <span v-if="isEdit">
                                    Done
                                </span>
                                <span v-else>
                                    Edit View
                                </span>
                            </button>
                            <button
                                v-if="!isEdit"
                                @click="sendMessage = true"
                                class="btn btn-primary float-right mr-2"
                            >
                                Send Message
                            </button>
                            <button 
                                class="btn btn-primary float-right mr-2" 
                                v-if="!isEdit" 
                                @click="createCustomer = true"
                            >
                                Create Contact
                            </button>
                        </h5>
                        </div>
                        <div
                          v-if="Object.keys(customers.data).length !== 0"
                          class="card-body"
                        >
                        <transition name="slide-fade">
                            <FieldsReorder
                            :activeFields="selected"
                            v-if="isEdit"
                            @update="reorderFields"
                            />
                        </transition>
                        <data-table
                            :headers="headers()"
                            :sort-url="'/customer/view/' + view.id"
                            :data="customers.data"
                            @sort="setSorting"
                        >
                            <template
                                v-for="elem in selected"
                                :slot="elem.key"
                                slot-scope="item"
                                >
                                <DataTableElement
                                    :key="elem.key"
                                    v-if="item.item._source[elem.key]"
                                    :item-key="elem.key"
                                    :item-value="item.item._source[elem.key]"
                                    :currency="item.item._source.currency"
                                />
                            </template>
                            <template #actions="{ item }">
                                <router-link
                                    :to="{ name: 'contact.single', params: { id: item._id } }"
                                    class="btn btn-sm btn-outline-secondary"
                                >
                                    View contact
                                </router-link>
                            </template>
                        </data-table>
                        <pagination
                            :data="customers"
                            :limit="3"
                            :show-disabled="true"
                            align="right"
                            @pagination-change-page="getCustomers"
                        >
                            <span slot="prev-nav" class="text-capitalize">
                            <i class="fas fa-chevron-left mr-2" />
                            Prev
                            </span>
                            <span slot="next-nav" class="text-capitalize">
                            Next
                            <i class="fas fa-chevron-right ml-2" />
                            </span>
                        </pagination>
                        <h4 class="h6 text-sentence mb-2 mt-7"><span class="dot prospect mr-2"></span> Prospect customers</h4>
                        </div>
                        <div v-else class="card-body">
                        <p class="text-muted">
                            This customer list is empty: You're seeing this message either
                            because: 1. There are no relevant customers to show for the
                            filter 2. The plugin/api is not correctly connected 3.
                            Automation.app is (unlikely) down. To resolve this, try new
                            filtering options or contact
                            <a href="mailto:nerds@automation.app">nerds@automation.app</a>.
                        </p>
                        <div class="p-4 rounded border-zip text-center">
                            <p class="mb-0">
                            No contacts to display!
                            </p>
                        </div>
                        </div>
                    </div>
                </ApiLoader>
            </div>
        </div>
    </component>
</template>
<script>
import Default from "@/layouts/default.vue";
import { mapGetters } from "vuex";
import { loadFromApi, updateApi, buildPaginationString } from "@/helpers/apiConnection";
import { updateApiConfigurationData } from "@/helpers/apiConfigurationData";
import DataTable from "@/components/DataTable/DataTable.vue";
import DataTableElement from "@/components/DataTable/DataTableElement.vue";
import Prompt from "@/components/UI/Prompt.vue";
import CreateCustomer from "@/components/UI/CreateCustomer.vue";
import MessageCustomer from "@/Page/Customer/MessageCustomer.vue";
import ConditionBuilder from "@/components/Automation/ConditionBuilder";
import ApiLoader from "@/components/UI/ApiLoader";
import Multiselect from "vue-multiselect";
import customerStatus from "@/helpers/customerStatus";
import OverviewSidebar from "@/components/Order/OverviewSidebar.vue";
import Tag from "@/components/UI/Tag.vue";
import FieldList from "@/components/Order/FieldList.vue";
import FieldsReorder from "@/components/Order/FieldsReorder.vue";
import { getValues } from "@/helpers/utilities";

export default {
    components: {
        Default,
        Prompt,
        CreateCustomer,
        MessageCustomer,
        ConditionBuilder,
        ApiLoader,
        Multiselect,
        OverviewSidebar,
        DataTable,
        DataTableElement,
        Tag,
        FieldList,
        FieldsReorder,
    },
    data() {
        return {
            layout: "Default",
            viewId: "",
            customers: {
                data: [],
                meta: {},
                links: {},
            },
            view: {
                id: null,
                data: {
                name: "",
                view_type: "customer",
                view: {},
                },
            },
            activeSearch: "",
            activeFilter: "",
            selectedRange: "",
            activeTag: "",
            selected: {},
            fieldKeys: [],
            page: 1,
            reRenderConditionBuilder: true,
            conditionData: {},
            customer_status: {
                key: "all",
                label: "All"
            },
            statusList: customerStatus,
            createCustomer: false,
            sendMessage: false,
            loading: false,
            isEdit: false,
        }
    },
    computed: {
        ...mapGetters(["getTags"]),
    },
    created() {
        if(this.view) {
            this.setupConfig();
        }
    },
    async mounted() {
        this.viewId = this.$route.params.id;
        if(this.$route.query.edit) {
            this.isEdit = this.$route.query.edit;
        }
        this.loading = true;
        this.view = await loadFromApi(`/configurationData/${this.viewId}`);
        await this.getCustomers(1);
        this.setupConfig();
        this.contactStatuses();
        this.loading = false;
    },
    methods: {
        setupConfig() {
            this.sortFields(this.view.data.view);
        },
        sortFields(viewData) {
            let fields = Object.values(Object.assign({}, viewData));
            fields = fields.sort((a, b) => (a.index > b.index ? 1 : -1));
            this.selected = {};
            fields.forEach((item) => {
                this.selected[item.key] = item;
            });
            this.fieldKeys = Object.keys(this.selected);
        },
        headers() {
            const header = [];
            this.fieldKeys.forEach((key) => {
                const item = this.selected[key];
                // if keys like billing_address.first_name, set sortable false
                if(item.key.includes(".")) {
                header.push({
                    title: item.name,
                    key: item.key,
                    sortable: false,
                    sortKey: item.key,
                });
                }else{
                header.push({
                    title: item.name,
                    key: item.key,
                    sortable: true,
                    sortKey: item.key,
                });
                }
            });
            header.push({
                title: "Actions",
                key: "actions",
            });
            return header;
        },
        setSorting(sorting) {
            const sort = sorting.sortKey;
            const direction = sorting.direction;
            this.$router
                .replace({
                query: {
                    sort: sort,
                    dir: direction,
                },
                })
                .catch((error) => {
                console.log(error);
                });
            this.getCustomers(this.page);
        },
        getCustomers: async function(page = 1) {
            const sortKey = this.$route.query.sort ?? "created_at";
            const sortDirection = this.$route.query.dir ?? "desc";
            const query = buildPaginationString(sortKey, sortDirection, page);
            const url = `/customer/view/${this.viewId}?${query}`;
            const conditionData = this.$route.query.filters;
            this.customers = await updateApi(url, conditionData);
        },
        filterCustomers(filter) {
            this.conditionData = {
                conditions: filter.conditions,
                conditionGroup: filter.conditions_group,
                conditionName: filter.name,
            };
            const sortKey = this.$route.query.sort ?? "created_at";
            const sortDirection = this.$route.query.dir ?? "desc";
            this.$router
                .replace({
                query: {
                    sort: sortKey,
                    dir: sortDirection,
                    filters: JSON.stringify(this.conditionData),
                },
                })
                .catch((error) => {
                    console.log(error);
                });
            // if no conditions are set, reset the conditionbuilder component
            if (Array.isArray(filter)) {
                if (filter.length === 0) {
                this.reRenderConditionBuilder = false;
                this.$nextTick(() => {
                    this.reRenderConditionBuilder = true;
                });
                }
            }
            this.activeFilter = filter.id;
            this.getCustomers(this.page);
        },
        selectCustomerTag(tag) {
            this.activeTag = tag;
            this.getCustomers(this.page);
        },
        resetFilters() {
            this.getCustomers(this.page);
        },
        filterTags(tag_slug) {
            if(tag_slug) {
                const tag_condition = {
                field: "tags",
                field_type: "tag",
                operator: "contains",
                search: "tags",
                type: "customer",
                value: tag_slug
                };
                let conditions = this.conditionData.conditions;
                if(conditions) {
                    conditions = conditions.filter(condition => condition.field_type !== "tag");
                    if(conditions.length === 0){
                        this.conditionData = {
                        conditionGroup: "AND",
                        conditions: [tag_condition]
                        };
                    }else {
                        conditions.push(tag_condition);
                        this.conditionData.conditions = conditions;
                    }
                } else {
                    this.conditionData = {
                        conditionGroup: "AND",
                        conditions: [tag_condition]
                    };
                }
            } else {
                this.conditionData = {};
                this.reRenderConditionBuilder = false;
                this.$nextTick(() => {
                this.reRenderConditionBuilder = true;
                });
            }
            const sortKey = this.$route.query.sort ?? "created_at";
            const sortDirection = this.$route.query.dir ?? "desc";
            this.$router
            .replace({
                query: {
                    sort: sortKey,
                    dir: sortDirection,
                    filters: JSON.stringify(this.conditionData),
                },
            })
            .catch((error) => {
                console.log(error);
            });
            this.getCustomers(this.page);
        },
        showAllTags(value) {
            if (value.length > 0) {
                const tag_names = [];
                this.getTags.filter((i) => {
                if (value.includes(i.slug)) {
                    tag_names.push(i.name);
                }
                });
                return tag_names.join();
            }
        },
        getValues,
        setConditionData(conditionData) {
            this.conditionData = conditionData;
            this.getCustomers();
        },
        contactStatuses() {
            const statusList = this.statusList;
            const all = {
                key: "all",
                label: "All"
            };
            if(!statusList.some(sl => sl.key === 'all')) {
                statusList.unshift(all);
            }
        },
        changeStatus(event) {
            this.customer_status = event;
            const status_condition = {
                field: "status",
                field_type: "status",
                operator: "contains",
                search: "status",
                type: "customer",
                value: this.customer_status.key
            };
            let conditions = this.conditionData.conditions;
            if(this.customer_status.key !== 'all') {
                if(conditions) {
                    conditions = conditions.filter(condition => condition.field_type !== "status");
                    if(conditions.length === 0){
                        this.conditionData = {
                        conditionGroup: "AND",
                        conditions: [status_condition]
                        };
                    }else {
                        conditions.push(status_condition);
                        this.conditionData.conditions = conditions;
                    }
                } else {
                    this.conditionData = {
                        conditionGroup: "AND",
                        conditions: [status_condition]
                    };
                }
            } else {
                if(conditions) {
                    conditions = conditions.filter(condition => condition.field_type !== "status");
                    this.conditionData.conditions = conditions;
                    if(conditions.length === 0) {
                        this.reRenderConditionBuilder = false;
                        this.$nextTick(() => {
                          this.reRenderConditionBuilder = true;
                        });
                    }
                    
                }
            }
            const sortKey = this.$route.query.sort ?? "created_at";
            const sortDirection = this.$route.query.dir ?? "desc";
            this.$router
            .replace({
                query: {
                    sort: sortKey,
                    dir: sortDirection,
                    filters: JSON.stringify(this.conditionData),
                },
            })
            .catch((error) => {
                console.log(error);
            })
            this.getCustomers(this.page);
        },
        createCustomerSuccess() {
            this.createCustomer = false;
            this.getCustomers(this.page);
        },
        setPage(page) {
            this.page = page;
            this.getCustomers(page);
        },
        async updateView(view) {
            this.loading = true;
            const newView = await updateApiConfigurationData(this.viewId, view);
            if (newView) {
                this.view = newView;
                await this.setupConfig();
                await this.getCustomers(1);
            }
            this.loading = false;
        },
        async addField(fieldKey, field) {
            const view = { ...this.view };
            field.index = view.data.view.length;
            view.data.view[fieldKey] = field;
            await this.updateView(view);
        },
        async removeField(fieldKey) {
            const view = { ...this.view };
            delete view.data.view[fieldKey];
            await this.updateView(view);
        },
        async reorderFields(fields) {
            this.sortFields(fields);
            const view = { ...this.view };
            view.data.view = fields;
            await this.updateView(view);
        },
    }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
}
</script>
<style scoped>
.multiselect {
  display: inline-block;
  width: 20%
}
.dot {
  height: 10px;
  width: 10px;
  background-color: rgba(137, 243, 129, 0.5) !important;
  border-radius: 50%;
  display: inline-block;
}

.dot.prospect {
  background: rgba(137, 243, 129, 0.5) !important;
  border: 1px solid #c1cce2;
}
</style>
