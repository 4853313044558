<template>
  <div class="orders-log col-12 mt-3">
    <data-table :headers="headers()" :data="orders">
      <template #status="{ item }">
        <status-badge :variant="item.status">
          {{ item.status }}
        </status-badge>
      </template>
      <template #created_at="{ item }">
        <span>
          {{ item.created_at | formatLocaleDateString }}
        </span>
      </template>
      <template #actions="{ item }">
        <router-link
          :to="{ name: 'order.single', params: { id: item.id } }"
          class="btn btn-sm btn-outline-secondary"
        >
          See order
        </router-link>
      </template>
    </data-table>
  </div>
</template>

<script>
// Components
import DataTable from "@/components/DataTable/DataTable.vue";
import StatusBadge from "@/components/UI/StatusBadge.vue";

export default {
  name: "OrdersLog",
  components: {
    DataTable,
    StatusBadge,
  },
  props: {
    orders: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    headers() {
      return [
        { title: "Number", key: "order_number" },
        { title: "Status", key: "status" },
        { title: "Date time", key: "created_at" },
        { title: "Actions", key: "actions" },
      ];
    },
  },
};
</script>
