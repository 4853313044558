<template>
  <div class="automations-log col-12 mt-3">
    <data-table :headers="headers()" :data="automations">
      <template #status="{ item }">
        <status-badge :variant="item.status">
          {{ item.status }}
        </status-badge>
      </template>
      <template #event="{ item }">
        <code>{{ item.event }}</code>
      </template>
      <template #updated_at="{ item }">
        <span>
          {{ item.updated_at | formatLocaleDateString }}
        </span>
      </template>
    </data-table>
  </div>
</template>

<script>
// Components
import DataTable from "@/components/DataTable/DataTable.vue";
import StatusBadge from "@/components/UI/StatusBadge.vue";

export default {
  name: "AutomationsLog",
  components: {
    DataTable,
    StatusBadge,
  },
  props: {
    automations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    headers() {
      return [
        { title: "Status", key: "status" },
        { title: "Activity", key: "event" },
        { title: "Updated", key: "updated_at" },
      ];
    },
  },
};
</script>
