<template>
  <div class="form-group col-12 mb-0">
    <button
      id="subject"
      type="submit"
      name="subject"
      class="btn btn-primary float-right"
      :disabled="loading"
    >
      <Spinner v-if="loading" />
      {{ title }}
    </button>
  </div>
</template>

<script>
import Spinner from "@/components/UI/Spinner.vue";
export default {
  name: "Button",
  components: {
    Spinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
